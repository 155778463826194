/**********************************************************
* Countdown timer
**********************************************************/

function getTimeRemaining(endtime) {
  var t = Date.parse(endtime) - Date.parse(new Date());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(id, endtime) {
  var clock = document.getElementById(id);
  var daysSpan = clock.querySelector('.days');
  var hoursSpan = clock.querySelector('.hours');
  var minutesSpan = clock.querySelector('.minutes');
  var secondsSpan = clock.querySelector('.seconds');

  function updateClock() {
    var t = getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      $("#post-quiz").attr('disabled',false);
      $(clock).prev().hide();
      clearInterval(timeinterval);
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock, 1000);
}

var deadline = new Date(Date.parse(new Date()) + 15 * 60 * 1000);
if (document.getElementById('clockdiv')) {
  initializeClock('clockdiv', deadline);
}

/**********************************************************
* Education time on page timer
**********************************************************/

if (document.getElementById('articulate-frame')) {
  var addTimeToPage = Routing.generate('update_time', {'seconds': 15});

  (function setTimeOnPage() {
    let t;
    let lastIdle = new Date().getTime();
    let isIdle;
    window.onload = startTimer;
    // DOM Events
    document.onmousemove = idleTimer;
    document.onkeypress = idleTimer;

    function idleTimer() {
      let current = new Date().getTime();
        console.log("Going idle");
        //location.href = 'logout.php'
    }
    idleTimer();

    function startTimer() {
        clearTimeout(t);
        t = setTimeout(addTime, 15000);
        // 1000 milisec = 1 sec
    }
    $.getJSON(addTimeToPage, function (response) {
      if (response.time == 'ok') {
        console.log(
          "You have received a letter, good sir. " +
          "I will have a quick lie-down and be on my way shortly."
        );
      }
      setTimeout(setTimeOnPage, 15000); // 15 seconds
    });
  })();
}

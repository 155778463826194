/**
 * For fields like # of hours add an attribute like data=number-only
 * to make sure we only get numbers. Add a toast helper.
 */
var toastNumberHTML = "<span>Numbers only please (including decimal)</span>"
// Make sure to show this toast 50% height. Check global.css

;(function ExcludeLetters() {
  let numberinputs = document.querySelectorAll("input[data=number-only]")
  Array.prototype.forEach.call(numberinputs, (numberinput) => {
    numberinput.addEventListener("input", (e) => {
      var key = e.which || e.charCode || e.keyCode || 0
      if (
        key != 9 && // allow tab
        key != 127 &&
        key != 8 &&
        key != 190 &&
        (key < 45 || key > 57) &&
        !(e.keyCode >= 96 && e.keyCode <= 105)
      ) {
        e.preventDefault()
        //M.toast({ html: toastNumberHTML })
        return key == null
      }
    })
  })
})()

if (window.location.pathname.indexOf("survey") > -1) {
  /* Tables
   ***********************************************************************/
  $("table.nolabels td label input+span").html("  ")
  /**
   * Get all tables of class min600 and add a notice above.
   */
  let tables = document.querySelectorAll("table.min600")
  tables.forEach((table) => {
    let notice = document.createElement("div")
    notice.classList.add(
      "card-panel",
      "amber",
      "grey-text",
      "text-darken-2",
      "hide-on-med-and-up"
    )
    notice.innerText =
      "The questions below are wider than your screen, please switch orientation to landscape mode (horizontally) or scroll to the side to view all options."
    table.before(notice)
  })

  /* Demographics
   ***********************************************************************/
  if (window.location.pathname.indexOf("demographics") > -1) {
    $("#phoneButton").click(function () {
      $("#phoneDetails").toggle()
    })

    /* OSA
     ***********************************************************************/
  } else if (window.location.pathname.indexOf("berlin") > -1) {
    var snore = function () {
      if ($("#berlin_snore_0:checked").val() == "Y") {
        $("#snore").show()
      } else {
        $("#snore").clearElements().hide()
      }
    }
    snore()
    $("input[id ^='berlin_snore_']").click(function () {
      snore()
    })
    var nod = function () {
      if ($("#berlin_drowsyNodVehicle_0:checked").val() == "1") {
        $("#nod_freq").show()
      } else {
        $("#nod_freq").clearElements().hide()
      }
    }
    nod()
    $("input[id ^='berlin_drowsyNodVehicle_']").click(function () {
      nod()
    })
    /*
     * RLS
     ***********************************************************************/
  } else if (window.location.pathname.indexOf("rls") > -1) {
    var rls = function () {
      $("#rlsmain").show()
      if (
        $("#rls_uncomfortable_1:checked").val() === "0" &&
        $("#rls_urgeMove_1:checked").val() === "0"
      ) {
        $("#rlsmain").clearElements().hide()
      }
    }
    rls()
    $("input[id ^='rls_u']").click(function () {
      rls()
    })
  } else if (window.location.pathname.indexOf("sleep") > -1) {
    /* Sleep Quality
     ***********************************************************************/
    $("td[id^='sleep']").children("label").html(" ")

    if (getMobileOperatingSystem() === "unknown") {
      $(
        "#sleep_workBedTime, #sleep_workWakeTime, #sleep_workWakeUp, #sleep_offBedTime, #sleep_offWakeTime, #sleep_offWakeUp"
      ).flatpickr({ enableTime: true, noCalendar: true, dateFormat: "H:i" })
    }
  } else if (window.location.pathname.indexOf("work") > -1) {
    /* Work
     ***********************************************************************/
    $("input[id='work_shiftDuration']").attr({ type: "number" })
  } else if (window.location.pathname.indexOf("health") > -1) {
    /* Health
     ***********************************************************************/
    //$("td[id^='health_']").children('label').html(' ');
  } else if (window.location.pathname.indexOf("ess") > -1) {
    /* ESS
     ***********************************************************************/
    //$("td[id^='ess_']").children('label').html(' ');
  } else if (window.location.pathname.indexOf("mbi") > -1) {
    /* Feedback
     ***********************************************************************/
    //M.toast({html: 'Add a toast for the table labels at some point'})
  } else if (window.location.pathname.indexOf("feedback") > -1) {
    /* Feedback
     ***********************************************************************/
    $("td[id^='feedback_']").children("label").html(" ")
  }
}

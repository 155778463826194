/**
 * materialize
 */
M.AutoInit();

/**
 * Clear elements
 */
jQuery.fn.clearElements = function() {
  return $(this).each(function() {
    $(this).find('input, select')
      .not(':button, :submit, :reset, :hidden, :radio, :checkbox')
      .val('')
      .removeAttr('checked')
      .removeAttr('selected');
    $(this).find('input:radio, input:checkbox')
      .removeAttr('checked')
      .removeAttr('selected');
  });
};

/**
 * Disable return key and Go button on ipad
 */
$("body").keydown(function(event){
    if(event.keyCode == 13) {
        document.activeElement.blur();
        return false;
    }
});

Date.prototype.yyyymmdd = function(separator) {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join(separator);
};

// OS detection for date/time pickers
var getMobileOperatingSystem = function () {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
};

/**
 * Screen size detection
 */

  const screenWidth  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const screenHeight = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
  let body = {
    width:screenWidth,
    height:screenHeight
  }
  fetch(Routing.generate('set_device_dims', {}),{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify(body)
  }).then((response) => response.json())
  .then((myJson) => console.log(myJson))


// Email validation
var timeout;
$('input[name=email]').keypress(function(){
    if(timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    timeout = setTimeout(function() {
      var booking_email = $('input[name=email]').val();
      if( /(.+)@(.+){2,}\.(.+){2,}/.test(booking_email) ){
        // valid email
        $("#email-error").hide();
      } else {
        $("#email-error").show();
        // invalid email
      }
    }, 2000);
});

/**
 * Phone number fix - no longer trigerred becauseof input mask
 */
$('input#cell')

	.keydown(function (e) {

		var key = e.which || e.charCode || e.keyCode || 0;
		var $phone = $(this);

		if ($phone.val().length > 14 && key > 48) {
			// 14 would be 2 brackets, 1 space, 1 dash, and 10 digits
			e.preventDefault();
			return key == null;
    }

  });

/**
 * Phone input mask that masks all type=tel
 */
var phoneMask =  function(e){
  var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
}
var phoneInput = document.querySelectorAll('input[type=tel]');
phoneInput.forEach(input => input.addEventListener('input',phoneMask));
phoneInput.forEach(input => input.addEventListener('blur',phoneMask));


/**
 * Copyright for footer
 */
document.getElementById('footer-year').textContent = new Date().getFullYear()
if (window.location.pathname.indexOf('knowledge/assessment') > -1) {

  // Randomize the questions from http://trentrichardson.com/2013/12/16/sort-dom-elements-jquery/
  var $questions = $('div#quiz'),
	$q = $questions.children('div.row');

  $q.sort(function(){
    return 0.5 - Math.random();
  });
  $q.detach().appendTo($questions);

  // Make the correct answer always green with a check to the right.
  // Make the remaining answers red if user selected

}

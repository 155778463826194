if (window.location.pathname.indexOf("appointment/schedule") > -1) {
  /*

  $('#bwh-map, #bwh-address').hide();
  $(".cardGroup, #faulkner-map, #faulkner-address").show();

  var updateCards= function  (){
      // This check stops old polling when a click happens
      var appointmentsUrl = Routing.generate('get_appointments', {'date':''});
      var jqxhr = $.ajax( appointmentsUrl )
        .done(function(data) {
          $("#cards").children().detach();
          let htmlstr = "";
          Object.keys(data).forEach(function(k) {
            htmlstr += data[k];
            //$("#cards").html(data[k]);
          });
          $("#cards").html(htmlstr);
          $(".cardGroup").show();
          setTimeout(function(){updateCards();}, 5000);
        })
        .fail(function() {
        })
        .always(function() {
      });
  };
  setTimeout(function(){updateCards();}, 100);

}

*/
  $(
    ".cardGroup, #bwh-map, #faulkner-map, #bwh-address, #faulkner-address"
  ).hide();
  var selectedDateStr = "";

  var updateCards = function (dateStr) {
    if (selectedDateStr == dateStr) {
      // This check stops old polling when a click happens
      var appointmentsUrl = Routing.generate("get_appointments", {
        date: dateStr,
      });
      var jqxhr = $.ajax(appointmentsUrl)
        .done(function (data) {
          $("#cards").children().detach();
          // Sanitize this
          $("#cards").html(data);
          $("#" + dateStr, ".cardGroup").show();
          setTimeout(function () {
            updateCards(dateStr);
          }, 5000);
        })
        .fail(function () {})
        .always(function () {
          $("#" + dateStr).show();
        });
    }
  };
  let dateArray = [];
  let minClinicDate = new Date();
  minClinicDate.setDate(minClinicDate.getDate() + 5); // give 5 day buffer for clinic slots
  let futureLimit = new Date();
  futureLimit.setMonth(futureLimit.getMonth() + 5); // book no further than 5 months out
  let fpDateOptions = {
    altInput: true,
    altFormat: "m-j-Y",
    inline: true,
    minDate: minClinicDate.yyyymmdd("-"),
    maxDate: futureLimit.yyyymmdd("-"),
    onChange: function (selectedDates, dateStr, instance) {
      selectedDateStr = dateStr;
      updateCards(dateStr);
      //$("#faulkner-map, #faulkner-address").show();
    },
  };

  let getAppointmentDates = new Promise((resolve, reject) => {
    $.ajax(Routing.generate("get_appointment_dates", {}))
      .done(function (data) {
        dateArray = data;
        resolve(dateArray);
      })
      .fail(function () {
        reject(null);
      });
  });

  getAppointmentDates
    .then((data) => {
      fpDateOptions.enable = [
        function (date) {
          if (data.indexOf(date.toISOString().split("T")[0]) == -1) {
            return false;
          }
          return true;
        },
      ];
      return data;
    })
    .catch((reason) => {
      //console.log('Handle rejected promise here.');
      fpDateOptions.disable = [
        function (date) {
          return (
            date.getMonth() < minClinicDate.getMonth() ||
            date.getDate() < minClinicDate.getDate()
          );
        },
      ];
    })
    .then((data) => {
      // do this for either rejetion or success
      // Add a check here for the data to move the month forward until the first data
      var fpCal = $("#calendar").flatpickr(fpDateOptions);
      // data is sorted so get the first date in ISO8601 date-only
      //let firstSlot = new Date(data[0])
      let availableSlots = data.filter((el) => {
        return new Date(el) > minClinicDate;
      });
      let firstSlot = new Date(availableSlots[0]);
      if (firstSlot) {
        fpCal.changeMonth(firstSlot.getMonth(), 0);
      }
    });

  var SelectedLocStr = "";

  function updateCardsFromLocation(locStr) {
    if (SelectedLocStr == locStr) {
      // This check stops old polling when a click happens
      var appointmentsUrl = Routing.generate("get_appointments_by_location", {
        location: locStr,
      });
      var jqxhr = $.ajax(appointmentsUrl)
        .done(function (data) {
          $("#cards").children().detach();
          $("#cards").html(data);
          $("#" + locStr, ".cardGroup").show();
          setTimeout(function () {
            updateCardsFromLocation(locStr);
          }, 5000);
        })
        .fail(function () {})
        .always(function () {
          $("#" + locStr).show();
        });
    }
  }
}
